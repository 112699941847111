<template>
  <v-row style="width: 100%">
    <v-col class="offset-md-0 offset-xl-3" cols="12" xl="3">
      <ContactForm :isContact="true" />
    </v-col>
    <v-col cols="12" xl="3">
      <LocationList />
    </v-col>
  </v-row>
</template>

<script>
import ContactForm from "@/components/ContactForm";
import LocationList from "@/components/LocationList";
export default {
  components: {
    ContactForm,
    LocationList,
  },
};
</script>

<style>
</style>

clickable name in email
extra vragen bij het boeken van een afspraaken
afspraken niet alle profielfoto's werken