<template>
  <v-form v-model="valid" width="1000">
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="firstname"
            :rules="nameRules"
            :counter="15"
            label="First name"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="12">
          <v-text-field
            v-model="lastname"
            :rules="nameRules"
            :counter="15"
            label="Last name"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="12">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-textarea
            solo
            name="input-7-4"
            :rules="explainRules"
            :counter="800"
            label="Waarmee kunnen wij u van dienst zijn?"
          ></v-textarea>
        </v-col>
        <v-btn
          :disabled="!valid"
          color="primary"
          class="mr-4"
          @click="validate"
        >
          Contacteren
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
    explainRules: [
      (v) =>
        (v && v.length <= 800 && v.length >= 10) ||
        "Dit veld moet tussen 10 en 800 karakters lang zijn",
    ],
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style>
</style>